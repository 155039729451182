import Player from '@vimeo/player';

export default {
    vimeoPlayer: function() {
        const videos = document.querySelectorAll('.player');

        if(videos.length) {
            for (const video of videos) {
                const overlay = video.querySelector('.player__overlay');

                // video settings
                const videoID =  video.getAttribute('data-id');
                const isLoop = video.getAttribute('data-loop');
                const isMuted = video.getAttribute('data-muted');
                const hasControls = video.getAttribute('data-controls');
                const hasAutoplay = video.getAttribute('data-autoplay');
                const isPlaysinline = video.getAttribute('data-playinsline');
                const quality = video.getAttribute('data-quality');
                let canClick = false;

                let videoWidth = video.getAttribute('data-width');

                if(overlay) {
                    canClick = video.getAttribute('data-click');
                }


                // if no data-width is specified get the value from css element width
                if(videoWidth === null) {
                    videoWidth = video.clientWidth;
                }

                const player = new Player(video, {
                    id: videoID,
                    width: videoWidth,
                    autoplay: hasAutoplay,
                    controls: hasControls,
                    muted: isMuted,
                    loop: isLoop,
                    playsinline: isPlaysinline,
                    quality: quality
                });

                if(overlay && canClick) {
                    let isPlaying = false;

                    overlay.addEventListener('click', function() {
                        if(isPlaying === false) {
                            player.play();
                            video.classList.add('player--is-playing');
                            isPlaying = true;
                        } else {
                            player.pause();
                            video.classList.remove('player--is-playing');
                            isPlaying = false;
                        }
                    });
                }
            }
        }
    }
}
