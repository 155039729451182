import Marquee3k from 'marquee3000';

export default {
    init: function() {
        const marqueeSelector = document.querySelectorAll('.marquee3k');


        if(marqueeSelector.length) {
            Marquee3k.init({
                selector: 'marquee3k',
            });

            const players = document.querySelectorAll('.player-play');

            for (const player of players) {
                player.addEventListener('click', function() {
                    Marquee3k.refreshAll();
                })
            }

        }


    }
}
