
export default {

  pageLoadedChecker: function () {
    (function (window, $) {
    const html = document.querySelector('html');

    function isCoarse() {
      const match = matchMedia('(pointer:coarse)').matches;
      if (match) {
        html.classList.add('coarse');
      }
    }

    let everythingLoaded = setInterval(function () {
      if (/loaded|complete/.test(document.readyState)) {
        clearInterval(everythingLoaded);

        html.classList.add('page-loaded');

        isCoarse();
      }
    }, 10);


      const videoloader = $('#video-loader');

      if (videoloader.length > 0){
        videoloader.click(function () {
          $('html').removeClass('fade-home')
        })
        }


    })(window, jQuery);
  }
}
