import gsap from 'gsap'
import Glide from '@glidejs/glide'

export default {
    singlePlayer: function () {
        const main = document.querySelector('.main-posts-list');

        if (main) {
            const players = document.querySelectorAll('.player-play');

            for (const player of players) {
                const target = document.getElementById(player.querySelector('video').getAttribute('data-target'));
                const close = target.querySelector('.close-player');

                console.log(close);
                let visible = false;

                player.addEventListener('click', function () {
                    if (visible === false) {
                        gsap.to(target, .5, {
                            autoAlpha: 1,
                            onComplete: function () {
                                visible = true;
                            }
                        })
                    }
                });

                close.addEventListener('click', function () {
                    console.log(visible);

                    if (visible === true) {
                        gsap.to(target, .5, {
                            onStart: function () {
                                if (document.querySelector('.player--is-playing')) {
                                    target.querySelector('.player__overlay').click();
                                }
                            },
                            autoAlpha: 0,
                            onComplete: function () {
                                visible = false
                            }
                        })
                    }
                })
            }
        }
    },

    sliderWork: function () {
        const sliders = document.querySelectorAll('.glide');

        if (sliders.length) {
            for (const slider of sliders) {

                const glide = new Glide(slider, {
                    type: 'carousel',
                    perView: 1,
                    startAt: 0,
                    controls: true,
                })

                glide.mount()
            }

        }


    }
}
