'use strict'

import global from './_global';
import lazyLoad from './_lazy-load';
import videos from './_videos';
import marquee from './_marquee'
import animations from './_animations'
import header from './_header'
import single from './_single'

document.addEventListener("DOMContentLoaded", function () {
    global.pageLoadedChecker();
    lazyLoad.lazyLoad();
    marquee.init();
    animations.smoothScroll();
    header.burgerMenu();
    videos.vimeoPlayer();
    single.singlePlayer();
    single.sliderWork();
});
