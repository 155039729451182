import ScrollMagic from "scrollmagic/scrollmagic/minified/ScrollMagic.min";
import {ScrollMagicPluginGsap} from "scrollmagic-plugin-gsap";
import Scrollbar from 'smooth-scrollbar';
import gsap from "gsap";
import { TweenMax, TimelineLite } from "gsap";

ScrollMagicPluginGsap(ScrollMagic, gsap);

export default {
  smoothScroll: function () {
    let isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

    let scenes = [];
    let y = 0;

    let scrollBarScene = Scrollbar.init(document.querySelector('#container-scroll'));

    let controller = new ScrollMagic.Controller({
      refreshInterval: 0,
      renderByPixels: true,
      continuousScrolling: false,
      syncCallbacks: true,
    });

    //update scrollY controller
    // if(isChrome){
    //   controller.scrollPos(function () {
    //     return y;
    //   });
    // }

    const revealElements = document.querySelectorAll('.reveal');

    const reveal = [];

    for (const element of revealElements) {
      scenes.push( new ScrollMagic.Scene({
        triggerElement: element,
        triggerHook: 'onEnter',
        offset: 100
      }).on('start', function (event) {
        let currentItem = event.target.triggerElement();

        gsap.to(currentItem, 1.2, {
          autoAlpha: 1,
          y: 0,
          ease: 'power5.out'
        })
      })
      .reverse(false)
      .addTo(controller)
    );
  }

    // SLOW FAST
    const slowElements = document.querySelectorAll('.speedSlow');

    const slow = [];

    for (const slowElement of slowElements) {
      const slowSpeed = TweenMax.to(slowElements, 1, { y: "600px", ease: 'power2.out' });
      scenes.push( new ScrollMagic.Scene({
            triggerElement: slowElement,
            duration: "300%",
            triggerHook: 'onCenter'
          }).setTween(slowSpeed)
      );
    }


  // Logo
  const logo = TweenMax.to('.logo-animation', 1, { width: '40%', ease: 'power2.out' });

  const moveLogo = new ScrollMagic.Scene({
    triggerElement: ".start-logo",
    duration: "55%",
    triggerHook: 'onCenter',
  }).setTween(logo);

  const logoTop = TweenMax.to('.logo-top', 1, { y: '-70pt', ease: 'power2.out' });

  const moveLogoTop = new ScrollMagic.Scene({
    triggerElement: ".start-logo",
    duration: '50%',
    triggerHook: 'onCenter',
  }).setTween(logoTop);

  // Height header
  const headerHeight = TweenMax.to('.height-header', 1, { height: '0', ease: 'power2.out' });

  const heightHeader = new ScrollMagic.Scene({
    triggerElement: ".start-logo",
    duration: "50%",
    triggerHook: 'onCenter',
  }).setTween(headerHeight);





  // Array of animation to call

  scenes.push( moveLogoTop, moveLogo, heightHeader);

  scenes.forEach(function(el) {
    controller.addScene(el);

    scrollBarScene.addListener(function() {
      el.refresh();
    });
  });

  (function (window, $) {
    $(window).on("resize load", function(){

      if ($(window).width() <= 991) {
        moveLogoTop.enabled(false);
        moveLogo.enabled(false);
      } else {
        moveLogoTop.enabled(true);
        moveLogo.enabled(true);
      }

      });

  })(window, jQuery);


  scrollBarScene.addListener(function(status) {
    y = status.offset.y;
    // console.log(scrollBarScene.offset['y']);
    // if (scrollBarScene.offset < 300) {
    // gsap.to('.header__logo', 0, {scale: scrollBarScene.offset['y'] / 10 });
    // }

    // if(isChrome){
    //   controller.update();
    // } else {
    //   for (let scene of scenes) {
    //     scene.refresh();
    //   }
    // }
  });

}
}
