export default {
  burgerMenu: function() {
    (function (window, $) {

      $('.header__burger').on('click', function(){
        $(this).toggleClass('open');
        $('.burger__menu').toggleClass('open-menu');
        $('.header__logo').toggleClass('logo-small');
      });

      const homeUrl = $('header a').attr('href');

      $(document).click(function(event) {
        if (event.target.classList.contains('pt-header')) {
          window.location.href = homeUrl;
        }
      });

      $('.burger__menu--job').click(function () {

        const filter = $(this).attr('id');

        $('.cat-active').removeClass('cat-active')
        $('.talent-name').show();
        $('.talent-name').not('[data-filter="'+filter+'"]').hide();
        //$('[data-filter="'+filter+'"]').show();
        //$('.talent-name').hide();
        //$('[data-filter="'+filter+'"]').show();
        $(this).addClass('cat-active');

      })

      $('#all').click(function () {
        $('.talent-name').show();
      })

    })(window, jQuery);
  }
}
